import React from 'react';
import Layout from '../components/Layout'
import '../styles/mentions.scss'

const PageConfidentialite = () => {
    return (
        <Layout>
            <h1>Politique de confidentialité</h1><span>(standard RGPD)</span>
            <section className="mentions-section">
                <article>
                    <h2>Nature des données collectées</h2>
                    <p>Le site https://b-com.xyz ne récolte aucune donnée d'état civil, d'identité, d'identification, aucune donnée relative à la vie personnelle (habitudes de vie, situation familiale, hors données sensibles ou
dangereuses) ni aucune donnée de connexion (adresses IP, journaux d'événements...).</p>
                </article>
                <article>
                    <h2>Communication des données personnelles à des tiers</h2>
                    <h4>Pas de communication à des tiers</h4>
                    <p>Vos données ne font l'objet d'aucune communication à des tiers. </p>
                </article>

                
                <article>
                    <h2>Collecte des données d'identité</h2>
                    <h4>Consultation libre</h4>
                    <p>La consultation du site https://b-com.xyz ne nécessite pas d'inscription ni d'identification préalable. Elle peut s'effectuer sans que
vous ne communiquiez de données nominatives vous concernant (nom, prénom, adresse, etc). Nous ne
procédons à aucun enregistrement de données nominatives pour la simple consultation du site https://b-com.xyz.</p>
                </article>
                <article>
                    <h2>Collecte des données d'identification</h2>
                    <h4>Consultation sans identification</h4>
                    <p>La consultation du site https://b-com.xyz ne nécessite pas d'inscription ni d'identification préalable. Elle peut s'effectuer sans que
vous ne communiquiez de données nominatives vous concernant (nom, prénom, adresse, etc). Nous ne
procédons à aucun enregistrement de données d'identification pour la simple consultation du site https://b-com.xyz.</p>
                </article>
                <article>
                    <h2>Géolocalisation</h2>
                    <h4>Consultation sans géolocalisation</h4>
                    <p>Nous ne collectons aucune donnée de géolocalisation pour la simple consultation du site https://b-com.xyz.</p>
                    
                </article>
                <article>
                    <h2>Collecte des données du terminal</h2>
                    <h4>Collecte des données de profilage et des données techniques à des fins de fourniture du service</h4>
                    <p>Certaines des données techniques de votre appareil sont collectées automatiquement par le site https://b-com.xyz. Ces
informations incluent notamment votre adresse IP, fournisseur d'accès à Internet, configuration matérielle,
configuration logicielle, type et langue du navigateur... La collecte de ces données est nécessaire à la fourniture
des services.</p>
                    <h4>Collecte des données techniques à des fins publicitaires, commerciales et statistiques</h4>
                    <p>Auncune donnée n'est collectée ou enregistrée à des fins publicitaires, commerciales ou statistiques. </p>
                </article>
                <article>
                    <h2>Cookies</h2>
                    <h4>Durée de conservation des cookies</h4>
                    <p>Conformément aux recommandations de la CNIL, la durée maximale de conservation des cookies est de 13
mois au maximum après leur premier dépôt dans le terminal de l'Utilisateur, tout comme la durée de la validité
du consentement de l’Utilisateur à l’utilisation de ces cookies. La durée de vie des cookies n’est pas prolongée
à chaque visite. Le consentement de l’Utilisateur devra donc être renouvelé à l'issue de ce délai.
</p>
                    <h4>Finalité cookies</h4>
                    <p>Les cookies peuvent être utilisés pour des fins statistiques notamment pour optimiser les services rendus à
l'Utilisateur, à partir du traitement des informations concernant la fréquence d'accès, la personnalisation des
pages ainsi que les opérations réalisées et les informations consultées.
Vous êtes informé que l'Éditeur est susceptible de déposer des cookies sur votre terminal. Le cookie enregistre
des informations relatives à la navigation sur le service (les pages que vous avez consultées, la date et l'heure
de la consultation...) que nous pourrons lire lors de vos visites ultérieures.</p>
                    <h4>Opt-in pour le dépôt de cookies</h4>
                    <p>Nous n'utilisons pas de cookies. Si nous devions en utiliser à l’avenir, vous en seriez informé préalablement et
                    auriez la possibilité de désactiver ces cookies.</p>

                </article>
                <article>
                    <h2>Conservation des données techniques</h2>
                    <h4>Durée de conservation des données techniques</h4>
                    <p>Les données techniques sont conservées pour la durée strictement nécessaire à la réalisation des finalités
visées ci-avant.</p>
                </article>
                
                <article>
                    <h2>Transfert des données personnelles à l'étranger</h2>
                    <h4>Pas de transfert en dehors de l'Union européenne</h4>
                    <p>L'Éditeur s'engage à ne pas transférer les données personnelles de ses Utilisateurs en dehors de l'Union
européenne.
</p>
                </article>
                <article>
                    <h2>Modification des CGU et de la politique de confidentialité</h2>
                    <h4>En cas de modification des présentes CGU, engagement de ne pas baisser le niveau de confidentialité de
manière substantielle sans l'information préalable des personnes concernées
</h4>
<p>Nous nous engageons à vous informer en cas de modification substantielle des présentes CGU, et à ne pas
baisser le niveau de confidentialité de vos données de manière substantielle sans vous en informer et obtenir
votre consentement.</p>
                </article>
                <article>
                    <h2>Droit applicable et modalités de recours</h2>
                    <h4>Clause d'arbitrage</h4>
                    <p>Vous acceptez expressément que tout litige susceptible de naître du fait des présentes CGU, notamment de
son interprétation ou de son exécution, relèvera d'une procédure d'arbitrage soumise au règlement de la
plateforme d'arbitrage choisie d'un commun accord, auquel vous adhérerez sans réserve.</p>
                </article>
                
            </section>
        </Layout>
    );
};

export default PageConfidentialite;